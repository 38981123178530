import React, { memo, useEffect, useState } from "react";
import { Button, Card, Icon, Skeleton, Tooltip } from "antd";
import { convertImage } from "../services/contents";
import OptionModal from "./OptionModal";
import { useTranslation } from 'react-i18next';
import { T } from "ramda";

const RequiredResolutionsCard = ({
  removeRelationsOnCampaign,
  customContents,
  setListResolutionWithContent,
  listResolutionWithContent,
  relation,
  loading,
  content = null,
  className,
  style,
  isWarning,
  onClickImage,
  isPaymentStatusApproved
}) => {

  const { t } = useTranslation();
  const [countDisplay, setCountDisplay] = useState(0);
  const [selectedContent, setSelectedContent] = useState(null);

  const handleDelete = () => {
    let newRelation = { ...relation };
    // Delete of customContents if the content is in the relation
    if (customContents && customContents.length > 0) {
      newRelation.contentsDisplays.forEach(newCustomContent => {
        if(selectedContent !== newCustomContent.contentId) return;
        const deltedCustomContent = customContents.find(
          customItem =>
            customItem.displayId === newCustomContent.displayId &&
            customItem.content.id === newCustomContent.contentId
        );

        if (deltedCustomContent) {
          removeRelationsOnCampaign(deltedCustomContent.displayId, deltedCustomContent.content.id )
        }
      });
      setSelectedContent(null);
    }

    // Delete of listResolutionWithContent
    newRelation.contentsDisplays = newRelation.contentsDisplays.filter(item => item.contentId !== selectedContent);
    newRelation.contents = newRelation.contents.filter(item => item.id !== selectedContent);

    const newListResolutionWithContent = listResolutionWithContent.map(item => {
      if (item.resolution_height === newRelation.resolution_height && item.resolution_width === item.resolution_width) {
        return newRelation;
      }
      return item;
    });

    const countContentInRelation = newListResolutionWithContent.find(item => item.contents.find(item => item.id === selectedContent));

    // if (!countContentInRelation) {
    //   const listOfContentsInCampaignAux = listOfContentsInCampaign.filter(item => item.id !== selectedContent) || [];
    //   setListOfContentsInCampaign(listOfContentsInCampaignAux);
    // }

    setListResolutionWithContent(newListResolutionWithContent);
  };

  useEffect(() => {
    if (relation && relation.contentsDisplays) {
      const newCount = content ?
        relation.contentsDisplays.reduce((count, item) => {
          return item.contentId === content.id ? count + 1 : count;
        }, 0)
        : 0;
      setCountDisplay(newCount);
    }
  }, [relation]);

  if (countDisplay === 0 && content) {
    return null
  }
  return (
    <div
      className={`card-wrapper ${className}`}
      style={style}
    >
      {loading ? (
        <Skeleton
          className="skeletonRequiredCard"
          active
          paragraph={{ rows: 1, width: 156 }}
        />
      ) : (
        <>
          <Card className={`cardContent ${isWarning ? "border-orange" : ""}`}>
            <span className="textCard contentTitleRelation">
              <p className="textCard centerCard" style={{ fontSize: "13px", marginRight: "5px" }}>
                {relation.resolution_width} x {relation.resolution_height}
              </p>
              {isWarning ? (
                <Icon type="warning" style={{ marginTop: "5px", color: "#FAAD14" }} />
              ) : (
                <Icon type="desktop" style={{ marginTop: "5px", color: content ? '#7CB354' : '#000000' }} />
              )}
              <Tooltip title={t('Displays added in this relation')}>
                <div className={countDisplay ? "greenCircle" : "circle"} >
                  {countDisplay}
                </div>
              </Tooltip>
            </span>
            {!content ?
              <div style={{ marginLeft: "7px", marginRight: "7px", paddingBottom: "52px" }}>
                <div className='cardNoContent contentCardImage' style={{ marginBottom: "6px" }}>
                  <Icon type="desktop" style={{ fontSize: "xx-large" }} />
                </div>
              </div>
              :
              <>
                <div className="alignImage">
                  <img
                    src={convertImage(content.file_thumb)}
                    className="contentCardImage"
                    onClick={onClickImage}
                    style={{ marginBottom: "6px" }}
                    alt=""
                  />
                </div>
                <div className='contentCardContent contentButtonCardResolution'>
                  <span className={"primaryCircle"} style={{ marginTop: "4px" }}>
                    {content.content_version_name || "V1"}
                  </span>
                  { !isPaymentStatusApproved &&
                    <Tooltip title={t('Remove relation between content and displays')}>
                        <Button
                          shape="circle"
                          className="icon-card cardRed"
                          onClick={() => setSelectedContent(content.id)}
                        >
                          <Icon type="delete" />
                        </Button>
                    </Tooltip>
                  }
                </div>
              </>
            }
          </Card>
          <OptionModal
            title={t('Atention!')}
            body={t('Are you sure you want to delete this relationship?')}
            visible={selectedContent}
            okText={t('Delete')}
            cancelText={t('Cancel')}
            onOk={handleDelete}
            onCancel={() => setSelectedContent(null)}
          />
        </>
      )}
    </div>
  );
}

export default memo(RequiredResolutionsCard);
